// Import; Three
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Html } from "@react-three/drei";
import { MovementHotspotProps } from "../../../models/Hotspot.model";
import { stylesBase } from "../../../styles/styles-base";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Arrow from "../icons/Arrow";

// Style the hotspot.
const useStyles = makeStyles(() => ({
  hotSpot: {
    borderStyle: "solid",
    borderWidth: 7,
    borderColor: "#fcb111",
    height: 75,
    width: 75,
    borderRadius: "50%",
    backgroundColor: "rgba(252, 177, 17, 0.8)",
    transition: "width 0.5s, border-radius 0.3s",
    textAlign: "center",
    "&:hover": {
      // paddingBottom: 30,
      display: "flex",
      alignItems: "center",
      minWidth: 130,
      borderRadius: 75,
      cursor: "pointer"
    },
    "&:hover $imageWrapper": {
      display: "none",
    },
    "&:hover $content": {
      display: "block",
      // verticalyAlign: "middle",
      opacity: 1,
      fontSize: 20,
      // padding: 5,
      marginTop: 1,
      // background: "green",
      padding: "0px 15px",
      width: "100%",
      textAlign: "center",
    },
  },
  mobileHotSpot: {
    borderStyle: "solid",
    borderWidth: 7,
    borderColor: "#fcb111",
    height: 75,
    width: 75,
    borderRadius: "20%",
    backgroundColor: "rgba(252, 177, 17, 0.45)",
    transition: "width 1s, border-radius 1s",
    textAlign: "center",
  },
  noSelect: {
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozuserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  content: {
    color: "#fff",
    marginTop: -12,
    fontSize: 0,
    display: "none",
    fontWeight: "600",
    opacity: 0,
    transition: "visibility 2s, opacity 2s, fontSize 2s",
  },
  mobileContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-4px",
    height: "35px",
    // background: "green",
    color: "white",
    opacity: 1,
    fontSize: 13,
    "$p": {
      margin: "0px"
    },
  },
  imageWrapper: {},
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

//-----------------------------------------------------------------------------------------------
// Hotspot component; render the movment, content and board hotspots
export default function MovementHotspot({
  hotspot,
  handleClick,
  theme,
}: MovementHotspotProps) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const styleDesk = {
    width: 40,
    height: 45,
    marginTop: 15,
    paddingTop: 0,
  };

  const styleMob = {
    width: 18,
    height: 27,
    marginTop: 5,
    paddingTop: 4
  };

  return (
    <mesh
      position={[hotspot.position.x, hotspot.position.y, hotspot.position.z]}
    >
      {matches ? (
        <Html scale={10} zIndexRange={[1, 0]} center={true}>
          <div
            className={clsx(classes.hotSpot, classes.noSelect)}
            onClick={() => {
              //Will trigger a specific method based on the type - Movement in this case
              handleClick(hotspot);
            }}
          >
            <div className={classes.imageWrapper}>
              <Arrow
                width={styleDesk.width}
                height={styleDesk.height}
                marginTop={styleDesk.marginTop}
                paddingTop={styleDesk.paddingTop}
              />
            </div>
            <div className={clsx(classes.content, classes.noSelect)}>
              <p>{hotspot.name}</p>
            </div>
          </div>
        </Html>
      ) : (
        <Html scale={5} zIndexRange={[1, 0]} center={true}>
          <div
            className={clsx(classes.mobileHotSpot, classes.noSelect)}
            onClick={() => {
              //Will trigger a specific method based on the type - Movement in this case
              handleClick(hotspot);
            }}
          >
            <Arrow
              width={styleMob.width}
              height={styleMob.height}
              marginTop={styleMob.marginTop}
              paddingTop={styleMob.paddingTop}
            />
            <div className={clsx(classes.mobileContent, classes.noSelect)}>
              <p>{hotspot.name}</p>
            </div>
          </div>
        </Html>
      )}
    </mesh>
  );
}
