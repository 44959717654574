//-----------------------------------------------------------------------------------------------
// Imports; React
import { Component, useEffect, useState } from "react";
import { ECGProps, ECGModel } from "../../models/Ecg.model";
import { Grid, Paper, Typography } from "@mui/material";
import { stylesBase } from "../../styles/styles-base";
import { Html } from "@react-three/drei";
// import { returnedData } from "../../../assets/testData";
// import "../../../index.css";
import { ECGLine } from "./ECGLine";

//-----------------------------------------------------------------------------------------------
// Hotspot to display patient information
export default function ECG({ hotspot, ecgData }: ECGProps) {
  // Heart Rate
  const heartRateParams = ecgData.heartRateParams;
  const [heartRateValue, setHeartRateValue] = useState(
    Math.round((heartRateParams.low + heartRateParams.high) / 2)
  );

  // SPO2
  const SPO2Params = ecgData.SPO2Params;
  const [SPO2Value, setSPO2Value] = useState(
    Math.round((SPO2Params.low + SPO2Params.high) / 2)
  );

  // NIBP
  const NIBPParams = ecgData.NIBPParams;
  const [NIBPValue, setNIBPValue] = useState(
    Math.round((NIBPParams.low + NIBPParams.high) / 2)
  );

  // Temperature
  const temperatureParams = ecgData.temperatureParams;
  const [temperatureValue, setTemperatureValue] = useState(
    Math.round((temperatureParams.low + temperatureParams.high) / 2)
  );

  // RR
  const RRParams = ecgData.RRParams;
  const [RRValue, setRRValue] = useState(
    Math.round((RRParams.low + RRParams.high) / 2)
  );

  // ETCO"
  const ETCO2Params = ecgData.ETCO2Params;
  const [ETCO2Value, setETCO2Value] = useState(
    Math.round((ETCO2Params.low + ETCO2Params.high) / 2)
  );

  //---------------------------------------------------------------------------------------------
  // sets the data to fluctuate between parameter
  function fluxValue(params: any, value: any, setter: any) {
    const fluxInterval = setInterval(() => {
      let newFlux = {
        lowVal: value - params.flux,
        highVal: value + params.flux,
      };
      let tempFluxValue =
        Math.floor(Math.random() * (params.high - params.low + 1)) + params.low;

      if (tempFluxValue > newFlux.highVal) {
        setter(newFlux.highVal);
      } else if (tempFluxValue < newFlux.lowVal) {
        setter(newFlux.lowVal);
      } else {
        setter(tempFluxValue);
      }
    }, params.interval);

    return function cleanup() {
      clearInterval(fluxInterval);
    };
  }

  //---------------------------------------------------------------------------------------------
  // Fluctuates the ecg information within parameters
  useEffect(() => {
    fluxValue(heartRateParams, heartRateValue, setHeartRateValue);
    fluxValue(SPO2Params, SPO2Value, setSPO2Value);
    fluxValue(NIBPParams, NIBPValue, setNIBPValue);
    fluxValue(temperatureParams, temperatureValue, setTemperatureValue);
    fluxValue(RRParams, RRValue, setRRValue);
    fluxValue(ETCO2Params, ETCO2Value, setETCO2Value);
  }, []);

  return (
    <mesh
      position={[hotspot.position.x, hotspot.position.y, hotspot.position.z]}
    >
      <Html scale={10} zIndexRange={[1, 0]} center={true}>
        <Paper style={stylesBase.desktopHotspotECG}>
          <Grid container>
            <Grid item container xs={12} spacing={2}>
              {/* --------------------------------------------------------------------------- */}
              {/* Heart Rate */}
              <Grid item xs={4}>
                <Grid item xs={12} style={stylesBase.ecgSectionHeader}>
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    HR
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={
                      97 < 50
                        ? { ...stylesBase.textRed, textAlign: "center" }
                        : 97 < 75
                        ? { ...stylesBase.textYellow, textAlign: "center" }
                        : { ...stylesBase.textGreen, textAlign: "center" }
                    }
                  >
                    {heartRateValue}
                  </Typography>
                </Grid>
              </Grid>

              {/* --------------------------------------------------------------------------- */}
              {/* SP02 */}
              <Grid item xs={4}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"space-between"}
                  style={stylesBase.ecgSectionHeader}
                >
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    SPO2
                  </Typography>
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    %
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={
                      73 < 50
                        ? { ...stylesBase.textRed, textAlign: "center" }
                        : 73 < 75
                        ? { ...stylesBase.textYellow, textAlign: "center" }
                        : { ...stylesBase.textGreen, textAlign: "center" }
                    }
                  >
                    {SPO2Value}
                  </Typography>
                </Grid>
              </Grid>

              {/* --------------------------------------------------------------------------- */}
              {/* NIBP */}
              <Grid item xs={4}>
                <Grid item xs={12} style={stylesBase.ecgSectionHeader}>
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    NIBP
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={{ ...stylesBase.textWhite, textAlign: "center" }}
                  >
                    {NIBPValue} (--)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              {/* --------------------------------------------------------------------------- */}
              {/* Temperature */}
              <Grid item xs={4}>
                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  xs={12}
                  style={stylesBase.ecgSectionHeader}
                >
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    Temperature
                  </Typography>
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    °C
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={{ ...stylesBase.textWhite, textAlign: "center" }}
                  >
                    {temperatureValue}
                  </Typography>
                </Grid>
              </Grid>

              {/* --------------------------------------------------------------------------- */}
              {/* RR */}
              <Grid item xs={4}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent={"space-between"}
                  style={stylesBase.ecgSectionHeader}
                >
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    RR
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={{ ...stylesBase.textWhite, textAlign: "center" }}
                  >
                    {RRValue}
                  </Typography>
                </Grid>
              </Grid>

              {/* --------------------------------------------------------------------------- */}
              {/* ETC02 */}
              <Grid item xs={4}>
                <Grid
                  item
                  container
                  justifyContent={"space-between"}
                  xs={12}
                  style={stylesBase.ecgSectionHeader}
                >
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    ETCO2
                  </Typography>
                  <Typography variant={"body2"} style={stylesBase.textWhite}>
                    mmHg
                  </Typography>
                </Grid>
                <Grid item xs={12} style={stylesBase.ecgSection}>
                  <Typography
                    variant={"h3"}
                    style={{ ...stylesBase.textWhite, textAlign: "center" }}
                  >
                    {ETCO2Value}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* --------------------------------------------------------------------------- */}
            {/* ECG */}
            <Grid item xs={12}>
              <div className="lineChart">
                <ECGLine
                  beatInterval={(60 / heartRateValue) * 1000}
                  beatScale={1}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Html>
    </mesh>
  );
}
