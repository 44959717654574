import { ProfilerProps } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

export interface TimelineProps {
  name: string;
}

export interface ProfileProps {
  name: string;
}

export default function Timeline({ screenName }: any) {
  console.log(screenName);
  return (
    <div className="centerContent">
      <div className="selfCenter spaceBetween">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName={screenName}
          options={{ height: 600 }}
          noScrollbar
        />
      </div>
    </div>
  );
}
