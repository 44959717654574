import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import { Grid } from '@mui/material';
import welshWoolLogo from "../../assets/images/welsh_wool_logo.svg";
import { useNavigate } from 'react-router-dom';
import { LanguageSwitch } from '../views/LanguageSwitch';
import { setLanguage } from '../../store/slices/language.slice';
import { useAppDispatch } from '../../store/hooks';
import { CountryFlag } from '../views/CountryFlag';

export default function NavBar() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Box component="div" sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{boxShadow: "none", color: "white", borderBottom: "6px solid white"}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate("/")}
          >
            <HomeIcon />
          </IconButton>
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            News
          </Typography> */}
          {/* <Button color="inherit">Login</Button> */}

          {/* <CountryFlag /> */}
          <LanguageSwitch onLanguageChange={(lang) => dispatch(setLanguage(lang))}/>
        </Toolbar>
      </AppBar>
      <Grid 
        container
        justifyContent="center"
         sx={{position: "relative"}}>
            <Box component="div" sx={{height: "100px", width: "120px", position: "absolute", top: "-60px", zIndex: 8}}>
                <img
                    onClick={() => navigate("/")}
                    src={welshWoolLogo}
                    style={{ width: "100%", minWidth: "100%", cursor: "pointer"}}
                    alt={"Something went wrong, please go home to use our site"}
                />
            </Box>
      </Grid>
    </Box>
  );
}