//-----------------------------------------------------------------------------------------------
// Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ScenarioConfig } from "../../models/scenario.model";
import { ScenarioLinkedModel } from "../../models/ScenarioLinked.model";
import { getScenario, getScenarioConfig } from "../thunks/scenario.thunks";


export interface ScenarioState{
  scenarioLoading: boolean;
  scenarioData: ScenarioLinkedModel | null, // what should this type be?
  scenarioError: boolean;

  scenarioConfigLoading: boolean,
  scenarioConfigData: ScenarioConfig[],
  scenarioConfigError: boolean,
}

//-----------------------------------------------------------------------------------------------
// Set initial scenario states for current sceanrio data and config
const scenarioInitialState: ScenarioState = {
  scenarioLoading: false,
  scenarioData: null,
  scenarioError: false,

  scenarioConfigLoading: false,
  scenarioConfigData: [],
  scenarioConfigError: false,
};

//-----------------------------------------------------------------------------------------------
// Redux slice to manage stored scenario data
const ScenarioSlice = createSlice({
  name: "scenario",
  initialState: scenarioInitialState,

  //---------------------------------------------------------------------------------------------
  // Reducers; to mutate scenario config and local data
  reducers: {
    setScenarioConfigData: (state: any, { payload }: PayloadAction<any>) => {
      state.scenarioData = payload;
    },
    setScenarioData: (state: any, { payload }: PayloadAction<any>) => {
      state.scenarioConfigData = payload;
    },
    setScenarioCurrentScene: (state: any, { payload }: PayloadAction<any>) => {
      state.scenarioCurrentScene = payload;
    },
  },

  //---------------------------------------------------------------------------------------------
  // ScenarioConfig; extra reducer manage errors and loading of data
  extraReducers: (builder: any) => {
    builder.addCase(getScenarioConfig.rejected, (state: any) => {
      state.scenarioConfigLoading = false;
      state.scenarioConfigError = true;
    });
    builder.addCase(getScenarioConfig.pending, (state: any) => {
      state.scenarioConfigLoading = true;
      state.scenarioConfigError = false;
    });
    builder.addCase(
      getScenarioConfig.fulfilled,
      (state: any, { payload }: PayloadAction<any[]>) => {
        state.scenarioConfigError = false;
        state.scenarioConfigLoading = false;
        state.scenarioConfigData = payload;
      }
    );

    //---------------------------------------------------------------------------------------------
    // ScenarioData; extra reducer manage errors and loading of data
    // These are not currently used, but will remain if needed.
    builder.addCase(getScenario.rejected, (state: any) => {
      state.scenarioLoading = false;
      state.scenarioError = true;
    });

    builder.addCase(getScenario.pending, (state: any) => {
      state.scenarioLoading = true;
      state.scenarioError = false;
    });

    builder.addCase(
      getScenario.fulfilled,
      (state: any, { payload }: PayloadAction<any[]>) => {
        state.scenarioError = false;
        state.scenarioLoading = false;
        state.scenarioData = payload;
      }
    );
  },
});

// Export the slice methods
export const { setScenarioConfigData } = ScenarioSlice.actions;
export default ScenarioSlice.reducer;
