// Import; Three
import { Info, CloseRounded } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { Circle, Html } from "@react-three/drei";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

// Import; Components
import { ContentModel } from "../../../models/scenario.model";
import { stylesBase } from "../../../styles/styles-base";
import { ContentHotspotProps } from "../../../models/Hotspot.model";

//Zoom
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

//-----------------------------------------------------------------------------------------------
// Hotspot component; render the movment, content and board hotspots
export default function ContentHotspot({
  hotspot,
  hotspotOpen,
  setHotspotOpen,
  theme,
}: ContentHotspotProps) {
  return (
    <mesh
      position={
        hotspotOpen
          ? [
              hotspot.position.x / 2,
              hotspot.position.y / 2,
              hotspot.position.z / 2,
            ]
          : [hotspot.position.x, hotspot.position.y, hotspot.position.z]
      }
    >
      <Html scale={10} zIndexRange={[1, 0]} center={true}>
        {/* When the hotspot modal isn't open */}
        {!hotspotOpen ? (
          <Grid
            style={stylesBase.titleHotspot}
            onClick={() => {
              setHotspotOpen(true);
            }}
            container
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Grid item style={stylesBase.imageWrapper}>
              <Info style={{ fontSize: 35 }} />
            </Grid>
            {hotspot.showTitleOnHotspot && (
              <Grid container item style={{ width: "max-content" }}>
                <Typography variant={"h6"} color={"#fff"}>
                  {hotspot.title}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          // When the hotspot modal is open
          <Paper style={stylesBase.desktopHotspotModalContent}>
            <Grid item container justifyContent={"space-between"}>
              <Typography variant={"h4"}>{hotspot.title}</Typography>
              <IconButton
                onClick={() => {
                  setHotspotOpen(false);
                }}
              >
                <CloseRounded />
              </IconButton>
            </Grid>
            {/* If content is defined - Map through each 'row' of content. */}
            {hotspot.content &&
              hotspot.content.map((row: ContentModel, index: number) => {
                return (
                  <Grid
                    item
                    key={index}
                    container
                    xs={12}
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{ paddingTop: "15px" }}
                  >
                    {/* If items exist then map them */}
                    {row.items &&
                      row.items.map((item: any, index: number) => {
                        return (
                          // Each item is equal width using 'xs={12/length} to equally split
                          // each mapped item.
                          <Grid
                            item
                            key={index}
                            container
                            xs={12 / row.items.length}
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            {/* If this item is text */}
                            {item.text && (
                              <Typography
                                style={{ color: "black" }}
                                variant={"body2"}
                              >
                                {item.text}
                              </Typography>
                            )}
                            {/* If this item is image */}
                            {item.image && (
                              <Zoom>
                                <img
                                  src={item.image}
                                  style={stylesBase.imageComponent}
                                />
                              </Zoom>
                            )}
                            {/* If this item is video */}
                            {item.video && (
                              <ReactPlayer
                                url={item.video}
                                style={stylesBase.audioComponent}
                                controls={true}
                                crossOrigin={"anonymous"}
                                width="100%"
                                height="100%"
                              />
                            )}
                            {/* If this item is audio */}
                            {item.audio && (
                              <ReactAudioPlayer
                                src={item.audio}
                                controls
                                style={stylesBase.audioComponent}
                              />
                            )}
                            {/* If this item is title */}
                            {item.title && (
                              <Typography
                                variant={"h4"}
                                style={stylesBase.fullWidth}
                              >
                                {item.title}
                              </Typography>
                            )}
                          </Grid>
                        );
                      })}
                  </Grid>
                );
              })}
          </Paper>
        )}
      </Html>
    </mesh>
  );
}
