import { CaseBoardModel, CaseInfoModel } from "../models/Case.model";
import { PatientModel } from "../models/patient.model";
import {
  SceneModel,
  ScenarioModel,
  SceneType,
} from "../models/scenario.model";
import { CaseLinkedModel, ScenarioLinkedModel, SceneLinkedModel } from "../models/ScenarioLinked.model";
// import { getCase } from "../../store/thunks/case.thunks";
// import { getPatient } from "../../store/thunks/patient.thunks";
// import { getCaseById } from "./case.helpers";
// import { getPatientById } from "./patient.helpers";

// Method to get board list from current scenario (Content scenes with cases attached)
// and set them to be used from 'boardData' state.
export async function getAndSetBoardList(
  scenarioData: ScenarioLinkedModel,
  setBoardData: Function
) {
  // Filter to only see content scenes - Where we can get case info!
  let content_scenes = scenarioData.scenes.filter(
    (val: SceneLinkedModel) => val.type === SceneType.CONTENT
  );
  let boardList: CaseBoardModel[] = [];

  // Loop through content scenes
  for (let i = 0; i < content_scenes.length; i++) {

    let scene = content_scenes[i];
    if (scene.case && scene.case.patient){
        //Get specific patient
        let caseData: CaseLinkedModel = scene.case;
        let patientData: PatientModel = scene.case.patient;

        // If the patient actually exists.
        if (patientData) {
          // Add item to 'boardList' array using data from scene, case and patient.
          boardList.push({
            roomName: scene.name,
            sceneId: caseData.sceneId,
            patientName: patientData.firstNames + " " + patientData.surname,
            boardItems: caseData.boardItems,
          });
        }
    }
  }
  setBoardData(boardList);
}
