import {
  Box,
  Button,
  CircularProgress,
  Grid,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import getTranslation from "../assets/localisation/getTranslation";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  Lang,
  selectLanguage,
  setLanguage,
} from "../store/slices/language.slice";
import NavBar from "../components/navbar/NavBar";
import ScenarioCard from "../components/views/ScenarioCard";
import AppFooter from "../components/footer/AppFooter";
import { HintsBox } from "../components/hints/HintsBox";
import { useEffect, useState } from "react";
import hintsCharacterImg from "../assets/images/character1.png";
import CommunityBox from "../components/views/CommunityBox";
import { getScenarioConfig } from "../store/thunks/scenario.thunks";
import { ScenarioConfig } from "../models/scenario.model";
import { ScenarioState } from "../store/slices/scenario.slice";
import type { RootState } from "../store/store";
import Partners from "../components/views/Partners";
import { Hints } from "../models/hints.model";
import { ActorHints } from "../components/hints/ActorHints";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function Home() {
  const dispatch = useAppDispatch();

  // const theme = useTheme();
  // const screenWidthGreaterThanMd: boolean = useMediaQuery(theme.breakpoints.up('sm'));

  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);
  // All scenarios - Taken from Redux store
  const { scenarioConfigError, scenarioConfigData, scenarioConfigLoading } =
    useAppSelector<ScenarioState>((state: RootState) => state.scenario);

  console.log("scenario config data");
  console.log(scenarioConfigData);

  const [showHints, setShowHints] = useState<boolean>(false);
  const [loadDelayFinished, setLoadDelayFinished] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getScenarioConfig());
  }, []);

  useEffect(() => {
    const timerId = window.setTimeout(() => {
      setLoadDelayFinished(true);
      setShowHints(true);
    }, 1500);
    return () => window.clearTimeout(timerId);
  }, []);

  const hints: Hints = {
    EN: [
      "welcome to welsh wool!",
      "select a scenario to begin a virtual tour!",
      "or scroll down to learn more about Welsh Wool!",
    ],
    WE: [
      "croeso i wlân cymraeg!",
      "dewiswch senario i ddechrau taith rithwir!",
      "neu sgroliwch lawr i ddysgu mwy am Wlân Cymru!",
    ],
  };

  return (
    <Grid container item sx={{ background: "#eee" }}>
      {showHints && <ActorHints 
                        actorName="John, Welsh Wool"
                        hints={hints} 
                        actorImageUrl={hintsCharacterImg} 
                        onAllHintsRead={() => setShowHints(false)} 
                        onExitClicked={() => setShowHints(false)}  
                        />}

      <NavBar />

      {/* title container  */}
      <Grid item xs={12} sx={{ marginTop: "65px", marginBottom: "20px" }}>
        <Typography
          variant="h3"
          textAlign="center"
          color="#555"
          sx={{
            fontWeight: "bold",
            visibility: { xs: "hidden", md: "visible" },
          }}
        >
          {getTranslation("title", currentSelectedLanguage)}
        </Typography>
      </Grid>

      {/* scenario cards container */}
      <Grid
        container
        justifyContent="center"
        xs={12}
        sx={{marginBottom: "55px"}}
      >
        {scenarioConfigData && loadDelayFinished ? (
          scenarioConfigData.map((scenarioConfigData: ScenarioConfig) => (
            <ScenarioCard scenarioConfigData={scenarioConfigData} />
          ))
        ) : (
          <Box component="div" sx={{ margin: "100px" }}>
            <CircularProgress size={150} />
          </Box>
        )}
      </Grid>
      <CommunityBox />
      <Partners />

      {/* <VirtusFooter />  */}
      <AppFooter />
    </Grid>
  );
}

export { Home };
