import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Lang, selectLanguage } from '../../store/slices/language.slice';
import welshFlagSvg from "../assets/images/flag_of_wales.svg";
import { Box } from '@mui/material';
import { CountryFlag } from './CountryFlag';
import { useAppSelector } from '../../store/hooks';

interface LanguageSwitchProps{
    onLanguageChange: (lang: Lang) => void;
}

function LanguageSwitch({onLanguageChange}: LanguageSwitchProps){

    const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

    return (
        <Box component="div" sx={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
            <CountryFlag /> 

            <Switch 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onLanguageChange(event.target.checked ? Lang.EN : Lang.WE);
                }}
                sx={{ }} 
                checked={currentSelectedLanguage == Lang.EN ? true : false} 
                color="default" />
        </Box>
    );
}

export {LanguageSwitch};