import welshFlagSvg from "../../assets/images/flag_of_wales.svg"
import englishFlagSvg from "../../assets/images/flag_of_england.svg";
import { useAppSelector } from "../../store/hooks";
import { Lang, selectLanguage } from "../../store/slices/language.slice";


function CountryFlag(){
    const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage); // should i pass in the flag as an svg image prop instead?

    return (
        <img 
            style={{width:"30px", height:"auto", borderRadius: "5px", border: "2px solid white"}} 
            src={currentSelectedLanguage == Lang.EN ? englishFlagSvg : welshFlagSvg} />
    )
}

export {CountryFlag};