//-----------------------------------------------------------------------------------------------
// Imports react, three
import { useRef } from "react";
import { extend, useThree, useFrame } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

// Extends the three orbit controls
extend({ OrbitControls });

//-----------------------------------------------------------------------------------------------
// Function for the three canvas controls to allow user interaction
export default function Controls() {
  // Ref passed through to allow updates
  const controlsRef = useRef();
  const { camera, gl } = useThree();

  // Updates the canvas
  useFrame(() => {
    // @ts-ignore
    controlsRef.current.update();
  });

  //---------------------------------------------------------------------------------------------
  // Retuiren three controls to use in the canvas
  return (
    // @ts-ignore
    <orbitControls
      enableZoom={true}
      autoRotate={false}
      ref={controlsRef}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.1}
      rotateSpeed={-0.5}
    />
  );
}