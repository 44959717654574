import { useParams } from "react-router-dom";

function Error(){
    const { errorMessage }: any = useParams();

    return (
        <h1> {`sorry there was an error, message: ${errorMessage}`} </h1> 
    )
}

export {Error};