import { CardMedia, Grid, Typography } from "@mui/material";
import theme from "../../styles/theme";
import ButtonFooterStyledCustomization from "./ButtonFooter";
import FooterImg from "../../assets/images/FooterImage.png";
import getTranslation from '../../assets/localisation/getTranslation';
import { Lang, selectLanguage } from "../../store/slices/language.slice";
import {useAppSelector} from '../../store/hooks';

//-------------------------------------------------------------------------------------------------------------------------
// Footer
function AppFooter() {
  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);
  
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <Grid
        container
        sx={{background: "white", marginTop: "20px"}}>
    {/* <div style ={{backgroundColor: 'green'}}> */}
      <Grid
        container
        sx={{
          // marginBlock: 10,
          borderTop: 4,
          borderColor: theme.palette.primary.main,
        }}
        justifyContent={"center"}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: {sx: "200px", sm: "auto"},
            
          }}
        >
          {/* <img
            src={FooterImg}
            style={{
              marginTop: 50,
              backgroundSize: "contain"
            }}
            alt="Virtus Logo"
          /> */}
          <CardMedia
            sx={{
              margin: 10,
              width: {xs: "75%", sm: "70%", md: "35%", xl: "20%"},
              transition: "all .1s ease-in-out"
            }}
          image={FooterImg}
          component="img" />

        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        display={{ xs: "none", md: "flex" }}
      >
        <Grid
          md={2.2}
          item
          sx={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={"/"}
            onClick={() => scrollToTop()}
          >
            {getTranslation("footHome", currentSelectedLanguage)}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={2.2}
          item
          sx={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={"/"}
            onClick={() => scrollToTop()}
          >
            {getTranslation("footHeritage", currentSelectedLanguage)}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={2.2}
          item
          sx={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={"/"}
            onClick={() => scrollToTop()}
          >
            {getTranslation("footWoolPresent", currentSelectedLanguage)}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={2.2}
          item
          sx={{
            border: 0,
            borderRight: 3,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={"/"}
            onClick={() => scrollToTop()}
          >
            {getTranslation("footWoolFuture", currentSelectedLanguage)}
          </ButtonFooterStyledCustomization>
        </Grid>
        <Grid
          md={2.2}
          item
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonFooterStyledCustomization
            to={"/community"}
            onClick={() => scrollToTop()}
          >
            {getTranslation("footCommunity", currentSelectedLanguage)}
          </ButtonFooterStyledCustomization>
        </Grid>
      </Grid>
     
        <Grid xs={12}           
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "25px"
              }}>
          <Typography color={theme.palette.text.secondary}>
            {getTranslation("footText", currentSelectedLanguage)}
          </Typography>
        </Grid>
    </Grid>
  );
}

export default AppFooter;
