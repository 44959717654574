import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import mentorMonImg from "../../assets/images/partners/p1_menter_mon_logo.png";
import bcImg from "../../assets/images/partners/p2_bc_logo.png";
import britishWoolImg from "../../assets/images/partners/p3_BW_logo.png";
import woolTestAuthImg from "../../assets/images/partners/p4_WTAE_logo.png";
import welshGovImg from "../../assets/images/partners/p5_EAFRD_+_WG_logo.png";
import { useAppSelector } from "../../store/hooks";
import { Lang, selectLanguage } from "../../store/slices/language.slice";
import getTranslation from "../../assets/localisation/getTranslation";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#eee" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#eee" }}
      onClick={onClick}
    />
  );
}

export default function Partners() {

  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

  const [partners, setPartners] = useState([
    { image: mentorMonImg, height: "150px" },
    { image: bcImg, height: "150px" },
    { image: britishWoolImg, height: "150px" },
    { image: woolTestAuthImg, height: "150px" },
    { image: welshGovImg, height: "150px" },
  ]);

  const matches = useMediaQuery("(min-width:1000px)");

  return (
    <Grid container xs={12} justifyContent={"space-evenly"}>
      <Grid xs={10} sx={{ width: "90%", margin: 20 }}>
        <Typography
          variant="h3"
          align="center"
          color="#555"
          sx={{ fontWeight: 700, marginBottom: 5 }}
        >
          {getTranslation("projectPartnersText", currentSelectedLanguage)}
        </Typography>
        <Slider
          slidesToShow={matches ? 3 : 1}
          {...{
            dots: true,
            infinite: true,
            speed: 1200,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            autoplay: true,
            autoplaySpeed: 3500,
          }}
          slidesPerRow={1}
        >
          {partners.map(({ image, height }: any) => (
            <div>
              <img
                src={image}
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  height: height,
                }}
              />
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}
