import { 
    Grid, 
    StepLabel, 
    Paper,
    Step,
    Stepper,
    Button,
    Box,
    useMediaQuery,
    useTheme,
    Fade
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import { ScenarioLinkedModel, SceneLinkedModel } from "../../models/ScenarioLinked.model";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { SceneStepperPermission } from "../../models/scenario.model";
import { useState } from "react";
import { Lang, selectLanguage } from "../../store/slices/language.slice";
import { useAppSelector } from "../../store/hooks";
import getTranslation from "../../assets/localisation/getTranslation";


interface ScenarioSceneStepperProps{
    currentScenario: ScenarioLinkedModel;
    currentScene: SceneLinkedModel;
    onSelectedScene: (selectedScene: SceneLinkedModel) => void
}

export default function ScenarioSceneStepper({currentScenario, 
    currentScene, onSelectedScene}: ScenarioSceneStepperProps){

    const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);
    
    const theme = useTheme();
    const [minimized, setMinimized] = useState<boolean>(false);
    const screenWidthGreaterThanMd: boolean = useMediaQuery(theme.breakpoints.up('sm'));
    const [indexOfLastScene, setIndexOfLastScene] = useState<number>(0);


    return (
        <Fade timeout={1500} in={true}>
            <Grid
                xs={12}
                justifyContent={"center"}
                container
                sx={{ 
                    bottom: "100px",
                    position: "absolute", 
                    zIndex: 15,
                    // border: "1px solid red"
                }}
            >
                {minimized ? 
                    <Button 
                        onClick={() => setMinimized(false)}
                        sx={{top: "100px", background: "white", 
                            borderRadius: "20px 20px 0px 0px", 
                            "&:hover":{background: "white"}}}> 
                        <KeyboardDoubleArrowUpIcon /> 
                    </Button> :
                    <Paper sx={{
                        padding: "10px", 
                        borderRadius: "20px 20px 0px 0px",
                        opacity: 1, 
                        zIndex: 25, 
                        position: "absolute", 
                        top: '20px',
                        background: "white"}}
                        >
                        <Button
                            onClick={() => setMinimized(true)}
                            sx={{borderRadius: "20px 20px 0px 0px", 
                                background: "white",
                                width: "15px", height: "30px", 
                                position: "absolute", 
                                left: "20px", 
                                top: "-25px", 
                                "&:hover": {background: "white"}}}>
                            <CloseIcon />
                        </Button>
                        <Grid 
                            container
                            xs={12}>
                            {/* {
                                currentScenario.viewMode === SceneStepperPermission.FREE ? 
                                    <LockOpenIcon /> : 
                                    <LockIcon />
                            } */}
                            <Button 
                                sx={{minWidth: {xs: "32px"}}}
                                onClick={() => {
                                    if(indexOfLastScene > 0){
                                        onSelectedScene(currentScenario.scenes[indexOfLastScene - 1]);
                                        setIndexOfLastScene(indexOfLastScene - 1);
                                    }
                                }}>
                                <NavigateBeforeIcon />
                                {screenWidthGreaterThanMd && getTranslation("scenarioStepperBackButtonText", currentSelectedLanguage)}
                            </Button>
                    
                            <Stepper
                                activeStep={currentScenario.scenes.indexOf(currentScene)}
                                alternativeLabel
                                sx={{overflowX: "auto", scrollbarWidth: "none"}}
                            >
                                {currentScenario.scenes.map((scene: any, index: number) => {
                                    return (
                                        <Step
                                        sx={{}}
                                        key={index}
                                        onClick={() => {    
                                            if(currentScenario.viewMode === SceneStepperPermission.FREE){
                                                onSelectedScene(scene);
                                                setIndexOfLastScene(index);
                                            }
                                        }}
                                        completed={index < indexOfLastScene ? true : false}
                                        >
                                            <StepLabel sx={{ color: "#888"}}>
                                                <strong>
                                                    {scene.name}
                                                </strong>
                                            </StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>

                            <Button 
                                onClick={() => {
                                    if(indexOfLastScene < currentScenario.scenes.length - 1){
                                        onSelectedScene(currentScenario.scenes[indexOfLastScene + 1]);
                                        setIndexOfLastScene(indexOfLastScene + 1);
                                    }
                                }}
                                sx={{minWidth: {xs: "32px"}}}>
                                {screenWidthGreaterThanMd && getTranslation("scenarioStepperNextButtonText", currentSelectedLanguage)} 
                                <NavigateNextIcon />
                            </Button>
                        </Grid>
                    </Paper>
                }
            </Grid>
        </Fade>)
}