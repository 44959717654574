import { Theme, createTheme } from "@mui/material/styles";

const AppTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#fcb111"
    },

    secondary: {
        main: "#555"
    },

    // background: {
    //   default: "#f7f7f7",
    //   paper: "#1D42B4",
    // },

    text: {
      // primary: "#fff",
      // secondary: "#1D42B4",
    },
  },
});

export default AppTheme;