import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import tempScenarioImage from "../../assets/images/temp_scenario_image.png";
import { TextScroll } from '../../TextScroll';
import { CardActionArea, Fade, styled } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScenarioConfig } from '../../models/scenario.model';
import { Lang, selectLanguage } from '../../store/slices/language.slice';
import { useAppSelector } from '../../store/hooks';
import getTranslation from '../../assets/localisation/getTranslation';

interface ScenarioCardButtonProps{
    makeHoverColor: boolean;
}

const ScenarioCardButton = styled(Button)<ScenarioCardButtonProps>(({makeHoverColor}) => ({
    background: makeHoverColor ? "#fcb111" : "#555", 
    color: makeHoverColor ? "#fff" : "#eee", 
    width: "150px", 
    fontWeight: "bold", 
    borderRadius: "15px", 
    // animation: "fadeIn 3s",
    "&:hover":
        {
            background: "#fcb111",
            color: "#fff"
        },
    // "@keyframes fadeIn": {
    //     "0%": {
    //         opacity: 0,
    //     },
    //     "100%": {
    //         opacity: 1,
    //     }     
    // }           
}));


interface ScenarioCardProps{
    scenarioConfigData: ScenarioConfig;
}

export default function ScenarioCard({scenarioConfigData}: ScenarioCardProps) {

  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

  const [makeButtonHoverColor, setMakeButtonHoverColor] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Fade timeout={1500} in={true}>
        {/* change margin (get rid of it) and make outer cards container have space between instead */}
        <Card sx={{ width: 325, borderRadius: "20px", margin: "20px" }}> 
            <CardActionArea 
                onClick={() => navigate(`/scenario/${scenarioConfigData.id}`)}
                onMouseOver={() => setMakeButtonHoverColor(true)}
                onMouseLeave={() => setMakeButtonHoverColor(false)}
                >
                <CardMedia
                    component="img"
                    height="190"
                    image={scenarioConfigData.image}
                    alt="green iguana"
                />
                <CardContent sx={{
                    display: "flex", 
                    paddingTop: "40px", 
                    flexDirection: "column", 
                    alignItems: "center", 
                    position: "relative"}}>
                    <Typography 
                        sx={{
                            minWidth: "120px",
                            maxWidth: "250px", 
                            padding: "3px 15px", 
                            textAlign: "center", 
                            borderRadius: "25px", 
                            background: "#fcb111", 
                            position: "absolute",
                            border: "4px solid white",
                            color: "white",
                            fontWeight: "bold",
                            top: "-23px"}} 
                        // gutterBottom 
                        variant="h6" 
                        component="div">
                            {/* <TextScroll 
                                text={scenarioConfigData.name}
                                unscrolledTextStylesObj={{ opacity: 0 }} 
                                delayBetweenEachCharInMs={1000 / 10}                        
                            /> */}
                            {currentSelectedLanguage === Lang.EN ? scenarioConfigData.name.en : scenarioConfigData.name.we}
                    </Typography>

                    <Typography sx={{textAlign: "center"}} variant="body1" color="text.secondary">
                        {currentSelectedLanguage === Lang.EN ? scenarioConfigData.desc.en : scenarioConfigData.desc.we}
                    </Typography>
                </CardContent>
                <CardActions sx={{justifyContent: "center", paddingTop: "0px", paddingBottom: "20px"}}>
                    <ScenarioCardButton size="large" makeHoverColor={makeButtonHoverColor}>
                        {getTranslation("scenarioButtonText", currentSelectedLanguage)}
                    </ScenarioCardButton>
                </CardActions>
            </CardActionArea>
        </Card>
    </Fade>
  );
}