//-----------------------------------------------------------------------------------------------
// Imports; React, pages
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { Home } from "./pages/Home";
import { Scenario } from "./pages/Scenario";
import { Error } from "./pages/Error";
import AppFooter from "./components/footer/AppFooter";
import { Community } from "./pages/Community";

// interface AppRoutesProps{
//     theme: Theme;
// }

//-----------------------------------------------------------------------------------------------
// Routing handler
export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/*------------------------------------------------------------------------------------*/}
        {/* Default route to the home page */}
        <Route path="/" element={<Home />}></Route>

        {/*------------------------------------------------------------------------------------*/}
        {/* Dynamic route to a specified scenario, with optional route to go to scene */}
        <Route path="/scenario/:scenarioId" element={<Scenario />}>
          <Route
            path="/scenario/:scenarioId/:sceneId"
            element={<Scenario />}
          ></Route>
        </Route>

        {/* route to community page */}
        <Route path="/community" element={<Community />}></Route>

        {/*------------------------------------------------------------------------------------*/}
        {/* Error page handling */}
        <Route path="/error/:errorMessage" element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
