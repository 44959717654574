import { Box, Typography, Paper } from "@mui/material";
import { AgricultureEvent } from "./EventsCalander";

export interface EventsCalanderItemProps{
    agricultureEvent: AgricultureEvent;
    itemContainerStylesObj?: any;
}

export function EventsCalanderItem({agricultureEvent, itemContainerStylesObj}: EventsCalanderItemProps){
    const monthsOfYear: string[] = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

    return (
        <Paper 
            onClick={() => window.open(agricultureEvent.eventLinkUrl, "_blank")}
            sx={{cursor: "pointer", borderRadius: "15px 15px 0px 0px", overflow: "hidden", ...itemContainerStylesObj}}>
            <Box component="div" sx={{borderRadius: "0px 0px 15px 0px", borderBottom: "3px solid #fcb111", borderRight: "3px solid #fcb111", verticalAlign: "top", width: "50px", height: "50px", display: "inline-block", padding: "10px", textAlign: "center"}}>
                <strong> 
                    {agricultureEvent.startDate.getDate()} <br /> 
                </strong>
                {monthsOfYear[agricultureEvent.startDate.getMonth()]} 
            </Box>
                
            <Box component="div" sx={{margin: "10px", marginTop: "3px", display: "inline-block"}}>
                <Typography
                    variant="h5"
                    textAlign="left"
                    color="#555"
                    component="div"
                    sx={{
                        
                    }}
                    >
                        {agricultureEvent.eventTitle}
                </Typography>

                <Typography
                    variant="body2"
                    textAlign="left"
                    color="#888"
                    sx={{}}
                    >
                    {`${agricultureEvent.startDate.toLocaleTimeString()}, ${agricultureEvent.startDate.toDateString()} - ${agricultureEvent.endDate.toLocaleTimeString()}, ${agricultureEvent.endDate.toDateString()}`} <br />
                    {agricultureEvent.eventLocation} <br />
                    {agricultureEvent.eventPlace}
                </Typography>
            </Box>
            <Box component="div" 
                sx={{display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden", width:"80px", height: "80px", background: "#aaa", float: "right", margin: "10px", borderRadius: "15px"}}>
                <img src={agricultureEvent.eventImageUrl} style={{width: "auto", height: "130%"}}/>
            </Box>
        </Paper>
    );
}