import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import getTranslation from "../assets/localisation/getTranslation";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  Lang,
  selectLanguage,
  setLanguage,
} from "../store/slices/language.slice";
import NavBar from "../components/navbar/NavBar";
import ScenarioCard from "../components/views/ScenarioCard";
import AppFooter from "../components/footer/AppFooter";
import { HintsBox } from "../components/hints/HintsBox";
import { useEffect, useState } from "react";
import hintsCharacterImg from "../assets/images/character1.png";
import CommunityBox from "../components/views/CommunityBox";
import { getScenarioConfig } from "../store/thunks/scenario.thunks";
import { ScenarioConfig } from "../models/scenario.model";
import { ScenarioState } from "../store/slices/scenario.slice";
import type { RootState } from "../store/store";
import Partners from "../components/views/Partners";
import { Hints } from "../models/hints.model";
import {
  AgricultureEvent,
  EventsCalander,
} from "../components/eventsCalander/EventsCalander";
import TwitterFeedBox from "../components/views/TwitterFeedBox";
import { ActorHints } from "../components/hints/ActorHints";

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function Community() {
  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

  const eventsTestData: AgricultureEvent[] = [
    {
      startDate: new Date("1995-12-17T03:24:00"),
      endDate: new Date("1995-12-17T04:55:00"),
      eventTitle: "Sheep Shaving Extravaganza",
      eventLocation: "The Depot",
      eventPlace: "Cardiff",
      eventImageUrl:
        "https://m.media-amazon.com/images/I/81ZRvq-WHaL._AC_SL1500_.jpg",
      eventLinkUrl:
        "https://m.media-amazon.com/images/I/81ZRvq-WHaL._AC_SL1500_.jpg",
    },

    {
      startDate: new Date("1995-11-18T03:24:00"),
      endDate: new Date("1995-11-19T04:55:00"),
      eventTitle: "Origins Of Wool",
      eventLocation: "Caldicot Castle",
      eventPlace: "Caldicot",
      eventImageUrl:
        "https://images.squarespace-cdn.com/content/v1/55aa6847e4b0ea19042c1b61/1458572275863-532GZBEKRZQK9MFOSJQF/wool+banner+1.jpg?format=2500w",
      eventLinkUrl:
        "https://images.squarespace-cdn.com/content/v1/55aa6847e4b0ea19042c1b61/1458572275863-532GZBEKRZQK9MFOSJQF/wool+banner+1.jpg?format=2500w",
    },

    {
      startDate: new Date("1995-12-18T03:24:00"),
      endDate: new Date("1995-12-19T04:55:00"),
      eventTitle: "Future Of Wool",
      eventLocation: "The Depot",
      eventPlace: "Cardiff",
      eventImageUrl:
        "https://cms.foxtrail.fjallraven.com/wp-content/uploads/2019/10/Erik_wool_edited_desktop_900x1742-2.jpg",
      eventLinkUrl:
        "https://cms.foxtrail.fjallraven.com/wp-content/uploads/2019/10/Erik_wool_edited_desktop_900x1742-2.jpg",
    },
  ];

  const dispatch = useAppDispatch();

  const [showHints, setShowHints] = useState<boolean>(false);
  const [loadDelayFinished, setLoadDelayFinished] = useState<boolean>(false);

  useEffect(() => {
    const timerId = window.setTimeout(() => {
      setLoadDelayFinished(true);
      setShowHints(true);
    }, 500);
    return () => window.clearTimeout(timerId);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const hints: Hints = {
    EN: [
      "here you can find ways to get involved with the community!",
      "discover the many organisations welsh wool support and work with",
      "and find upcoming agricultural events happening via the calander",
    ],
    WE: [
      "yma gallwch ddod o hyd i ffyrdd o ymwneud â'r gymuned!",
      "darganfyddwch y sefydliadau niferus y mae gwlan cymru yn eu cefnogi ac yn gweithio gyda nhw",
      "a dod o hyd i ddigwyddiadau amaethyddol sydd ar ddod yn digwydd trwy'r Calander",
    ],
  };

  return (
    <Grid container item sx={{ background: "#eee" }}>
      {showHints && <ActorHints 
                        actorName="John, Welsh Wool"
                        hints={hints} 
                        actorImageUrl={hintsCharacterImg} 
                        onAllHintsRead={() => setShowHints(false)} 
                        onExitClicked={() => setShowHints(false)}  
                        />}

      <NavBar />

      {/* title container  */}
      {/* <Grid item xs={12} sx={{ marginTop: "60px", marginBottom: "20px" }}>
        <Typography
          variant="h3"
          textAlign="center"
          color="#555"
          sx={{
            fontWeight: "bold",
            visibility: { xs: "hidden", md: "visible" },
            marginBottom: "40px"
          }}
        >
          {getTranslation("communityPageTitle", currentSelectedLanguage)}
        </Typography>

        <Grid item xs={9} sx={{ margin: "0 auto" }}>
          <Typography variant="body1" textAlign="center" color="#555" sx={{}}>
            {getTranslation(
              "communityPageDescription",
              currentSelectedLanguage
            )}
          </Typography>
        </Grid>
      </Grid> */}

      <Grid container justifyContent="center" xs={12}>
        <Typography
          variant="h3"
          textAlign="center"
          color="#555"
          component="div"
          sx={{
            fontWeight: "bold",
            width: "100%",
            marginTop: "75px",
            marginBottom: "40px",
          }}
        >
          {getTranslation("communityEventsTitle", currentSelectedLanguage)}
        </Typography>

        <EventsCalander events={eventsTestData} />
      </Grid>
      <Grid
        container
        // columnSpacing={2}
        justifyContent="center"
        // spacing={1}
        xs={12}
        sx={{ marginBottom: "25px" }}
      >
        <TwitterFeedBox />
      </Grid>
      <AppFooter />
    </Grid>
  );
}

export { Community };
