import { colors } from "@mui/material";

export const FontFamily: string = "Roboto";

export const IconColours: string[] = [
  colors.red[400],
  colors.pink[400],
  colors.purple[400],
  colors.deepPurple[400],
  colors.indigo[400],
  colors.blue[400],
  colors.lightBlue[400],
  colors.cyan[400],
  colors.teal[400],
  colors.green[400],
  colors.lightGreen[400],
  colors.amber[400],
  colors.orange[400],
  colors.deepOrange[400],
];

const stylesBase = {
  fullHeight: {
    height: "100%",
  },
  desktopHotspotModalTitle: {
    backgroundColor: "rgba(252, 177, 17)",
    padding: 15,
    zIndex: 1000,
    borderRadius: "20px 20px 0px 0px",
  },
  desktopHotspotModalContent: {
    minWidth: 500,
    minHeight: 300,
    maxHeight: 400,
    overflow: "auto",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: 20,
    zIndex: 1000,
    borderRadius: "  0px 0px 20px 20px",
  },

  desktopHotspotVideoTitle: {
    backgroundColor: "rgba(252, 177, 17)",
    zIndex: 1000,
    padding: 15,
    borderRadius: "20px 20px 0px 0px",
  },
  desktopHotspotVideoContent: {
    minWidth: 500,
    // height: 700,
    minHeight: 300,
    maxHeight: 400,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "5px 5px 0px 5px",
    zIndex: 1000,
    // borderRadius: "0px 0px 20px 20px",
  },
  desktopHotspotECG: {
    minWidth: 750,
    minHeight: 400,
    overflow: "auto",
    backgroundColor: "#372E30",
    padding: 15,
  },
  ecgSectionHeader: {
    backgroundColor: "#372E30",
  },
  ecgSection: {
    color: "white",
    backgroundColor: "#4D4345",
    padding: "15px",
  },
  textWhite: {
    color: "white",
  },
  textGreen: {
    color: "greenyellow",
  },
  textRed: {
    color: "red",
  },
  textYellow: {
    color: "yellow",
  },
  textCenter: {
    textAlign: "center",
  },
  mediaComponent: {
    width: "100%",
    height: "auto",
  },
  paperWorkModalAnswerUnscrolledText: {
    opacity: 0,
  },
  paperComponentWhite: {
    backgroundColor: "white",
  },
  imageComponent: {
    width: "auto",
    maxWidth: "100%",
    height: "auto",
    maxHeight: "300px",
  },
  audioComponent: {
    width: "90%",
  },
  fullHeightWidth: {
    height: "100%",
    width: "100%",
  },
  list: {
    overflowY: "auto",
  },
  whiteColor: {
    color: "white",
  },
  fullWidth: {
    width: "100%",
  },
  borderRadius: {
    borderRadius: 5,
  },
  whiteBorder: {
    border: "2px solid #fff",
  },
  noSelect: {
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozuserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  imageWrapper: {
    opacity: 1,
    cursor: "pointer",
    transition: "opacity 0.3s, display 0.3s",
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  margin: {
    marginTop: "10vh",
    padding: 20,
  },
  root: {
    flexGrow: 1,
  },
  bottomRight: {
    top: window.screen.height - 50,
    right: "15px",
    zIndex: 30,
    backgroundColor: "#1D42B4",
    color: "white",
    borderRadius: 25,
  },
  topLock: {
    top: 0,
  },
  bottomLock: {
    bottom: 0,
  },
  rightLock: {
    right: 0,
  },
  leftLock: {
    left: 0,
  },
  titleHotspot: {
    cursor: "pointer",
    borderRadius: 24,
    opacity: 0.8,
    display: "flex",
    width: "max-content",
    padding: "0 5px",
  },
};

export { stylesBase };
