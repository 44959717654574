import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppTheme from './styles/theme';
import { ThemeProvider } from '@emotion/react';
import AppRoutes from './AppRoutes';
import { Home } from './pages/Home';

function App() {

  

  return (
      <ThemeProvider theme={AppTheme}>
        <AppRoutes />
      </ThemeProvider>
  );
}

export default App;
