import * as React from "react";
import { Paper, PaperProps } from "@mui/material";

import {styled } from "@mui/material/styles";

const PaperCustom = styled(Paper)<PaperProps>((props: any) => ({
  width: "100%",
  backgroundColor: "#eee",
  boxShadow: "none",
}));

interface StyledCustomizationProps {
  children?: React.ReactChild;
  sx?: any;
}

export default function PaperStyledCustomization({
  children,
  sx,
}: StyledCustomizationProps) {
  return <PaperCustom sx={sx}>{children}</PaperCustom>;
}
