import { useEffect } from "react";
import { Hints } from "../../models/hints.model";
import { HintsBox } from "./HintsBox";

// import textScrollSound from "./sounds/textScrollSound.mp3";

export interface ActorHintsProps{
    actorName: string;
    hints: Hints;
    actorImageUrl: string;
    onAllHintsRead: () => void;
    onExitClicked: () => void;
}

export function ActorHints({actorName, hints, actorImageUrl, onAllHintsRead, onExitClicked}: ActorHintsProps){

    // const audio = new Audio("./sounds/textScrollSound.mp3");
    // useEffect(() => {
    //     audio.play();
    // }, [hints])

    return (
        <div
          style={{
            position: "absolute",
            top: "125px",
            left: "10px",
            zIndex: 100
          }}
        >
             <div 
                onClick={onExitClicked}
                style={{
                    boxShadow: "2px 3px 4px -2px #888",
                    cursor: "pointer",
                    position: "absolute", 
                    top: "-10px", 
                    width: "15px", 
                    height: "15px", 
                    // padding: "5px",
                    borderRadius: "50%", 
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                    color: "#888",
                    fontSize: "0.6em"}}> 
                <strong> ✕ </strong>
            </div>
          <div
            style={{
              boxShadow: "2px 3px 4px -2px #888",
              width: 65,
              height: 65,
              borderRadius: "50%",
              background: "#bbb",
              marginRight: "5px",
              overflow: "hidden",
              border: "6px solid white",
              float: "left",
              position: "relative"
            }}
          >
           
            <img src={actorImageUrl} style={{ width: "110%", height: "auto" }} />
          </div>
          <HintsBox
            personName={actorName}
            hints={hints}
            onAllHintsRead={onAllHintsRead}
          />
        </div>
    );
}