//-----------------------------------------------------------------------------------------------
// Imports; React, material ui
import { useEffect, useState } from "react";
import { Theme } from "@mui/material";

// Import; Components models
import { HotspotType } from "../../models/scenario.model";
import { CaseBoardModel } from "../../models/Case.model";
import { HotspotProps } from "../../models/Hotspot.model";
import MovementHotspot from "./types/MovementHotspot";
import ContentHotspot from "./types/ContentHotspot";
import BoardHotspot from "./types/BoardHotspot";
import ECG from "../nhs/ECG";
import { getAndSetBoardList } from "../../helpers/hotspot.helpers";
import InfoHotspot from "./types/InfoHotspot";
import VideoHotspot from "./types/VideoHotspot";

//-----------------------------------------------------------------------------------------------
// Hotspot component; render the movment, content and board hotspots
export default function Hotspot({
  hotspot,
  handleClick,
  scenarioData,
  setCurrentScene,
  theme,
}: HotspotProps) {
  const [hotspotOpen, setHotspotOpen] = useState(false);
  const [boardData, setBoardData] = useState<CaseBoardModel[] | null>(null);

  //---------------------------------------------------------------------------------------------
  // If hotspot is of type board prep the data
  useEffect(() => {
    if (hotspot && hotspot.type === HotspotType.BOARD) {
      //Method to get the board list data
      //Through checking the cases of each content scene
      getAndSetBoardList(scenarioData, setBoardData);
    }
  }, [hotspot]);

  //---------------------------------------------------------------------------------------------
  // Function to render a Hotspot appropriately based on its type
  function renderHotspot() {
    if (hotspot) {
      switch (hotspot.type) {
        //---------------------------------------------------------------------------------------
        // Movement hotspot
        case HotspotType.MOVEMENT: {
          if (hotspot) {
            return (
              <MovementHotspot
                hotspot={hotspot}
                handleClick={handleClick}
                theme={theme}
              />
            );
          } else {
            return null;
          }
        }
        case HotspotType.INFO: {
          if (hotspot) {
            return (
              <InfoHotspot
                hotspotOpen={hotspotOpen}
                setHotspotOpen={setHotspotOpen}
                hotspot={hotspot}
                theme={theme}
              />
            );
          } else {
            return null;
          }
        }
        case HotspotType.VIDEO: {
          if (hotspot) {
            return (
              <VideoHotspot
                hotspotOpen={hotspotOpen}
                setHotspotOpen={setHotspotOpen}
                hotspot={hotspot}
                theme={theme}
              />
            );
          } else {
            return null;
          }
        }

        //---------------------------------------------------------------------------------------
        // Content hotspot
        case HotspotType.CONTENT: {
          return (
            <ContentHotspot
              hotspot={hotspot}
              hotspotOpen={hotspotOpen}
              setHotspotOpen={setHotspotOpen}
              theme={theme}
            />
          );
        }

        //---------------------------------------------------------------------------------------
        // ECG hotspot
        case HotspotType.ECG: {
          if (hotspot.ecgData) {
            return <ECG hotspot={hotspot} ecgData={hotspot.ecgData} />;
          }
        }
      }
    }
  }
  return <>{renderHotspot()}</>;
}
