import { configureStore } from '@reduxjs/toolkit';
import languagesReducer from "./slices/language.slice";
import scenarioReducer from "./slices/scenario.slice";

export const store = configureStore({
  reducer: {
      scenario: scenarioReducer,
      languages: languagesReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;    