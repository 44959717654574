import { useEffect, useRef, useState } from "react";
import { Hints } from "../../models/hints.model";
import { useAppSelector } from "../../store/hooks";
import { Lang, selectLanguage } from "../../store/slices/language.slice";
import { SpeechBubble, SpeechBubbleDirection } from "./SpeechBubble";

interface HintsBoxProps{
    hints: Hints;
    personName: string;
    onAllHintsRead: () => void;
}

export function HintsBox({hints, onAllHintsRead, personName}: HintsBoxProps){

    const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

    const languageKey: string = Lang[currentSelectedLanguage];

    // console.log("HINT BOX RE-RENDER");

    const hintIndexRef = useRef<number>(0);
    const [currentHint, setCurrentHint] = useState<string>(hints[languageKey][hintIndexRef.current]);

    useEffect(() => {
        setCurrentHint(hints[languageKey][hintIndexRef.current]);
    }, [languageKey]);

    function nextHint(){
        if(hints[languageKey].length - 1 > hintIndexRef.current){
            hintIndexRef.current++;
            setCurrentHint(hints[languageKey][hintIndexRef.current]);
        }else{
            // no more hints left, user has seen/read them all
            onAllHintsRead();
        }
    }

    return (
        <SpeechBubble 
            onWholeTextHasBeenReadByUser={()=>{
                nextHint();
            }}
            personName={hintIndexRef.current == 0 ? personName : undefined}
            qoutesOn={true}
            text={currentHint} 
            speechBubbleDirection={SpeechBubbleDirection.LEFT} 
            scrollText={false} />
    )
}