//-----------------------------------------------------------------------------------------------
// Scenario config, to handle the scenario home cards and select a scenario
export interface ScenarioConfig {
  id: number;
  active: boolean;
  name: {
    en: string;
    we: string;
  };
  desc: {
    en: string;
    we: string;
  };
  image: string;
}

//----------------------------------------------------------------------------------------------
// One to one relationship - ID <-> ID - Between ScenarioConfig and Scenario



export interface SceneOrderObject {
  id: number;
  sceneId: number;
}

// permission type here
export enum SceneStepperPermission{
  FREE = "FREE",
  LOCKED = "LOCKED"
}

//-----------------------------------------------------------------------------------------------
// Scenario, specific scenario eg. ER shift with the visual and related case data
export interface ScenarioModel {
  id: number;
  scenes: SceneModel[];
  viewMode: SceneStepperPermission;
  scenesOrder?: SceneOrderObject[];
}

//-----------------------------------------------------------------------------------------------
// Scene, Handles each view eg. a corridor or patient bay can have case info attached
export interface SceneModel {
  id: number;
  name: string;
  type: SceneType;
  image: any;
  preview?: string;
  scenes?: any;
  textures?: any;
  audio?: string;
  hotspots?: HotspotModel[];
  caseId?: number;
}

//-----------------------------------------------------------------------------------------------
// Possible scene types,
export enum SceneType {
  BRIEF = "brief",
  GENERAL = "general",
  CONTENT = "content",
}

//-----------------------------------------------------------------------------------------------
// Hotspots, handles user interaction, eg. movment action or opening a modal
export interface HotspotModel {
  id: number;
  position: PositionModel;
  type: HotspotType;
  stage?: StageModel[];
  title?: string;
  name?: string;
  showTitleOnHotspot?: boolean;
  icon?: string;
  action?: number;
  content?: ContentModel[];
  ecgData?: ECGDataModel;
}

//-----------------------------------------------------------------------------------------------
// Hotspots, handles user interaction, eg. movment action or opening a modal
export interface StageModel {
  id: number;
  position: number;
  name: string;
}

//-----------------------------------------------------------------------------------------------
// Possible hotspot types,
export enum HotspotType {
  MOVEMENT = "movement",
  INFO = "info",
  BOARD = "board",
  CONTENT = "content",
  ECG = "ecg",
  VIDEO = "video",
}

//-----------------------------------------------------------------------------------------------
// Position; for three js coordinate
export interface PositionModel {
  x: number;
  y: number;
  z: number;
}

//-----------------------------------------------------------------------------------------------
// Context; for model content and data
export interface ContentModel {
  items: ItemsModel[];
}

export interface ItemsModel {
  text?: string | undefined;
  image?: string | undefined;
  audio?: string | undefined;
  video?: string | undefined;
  title?: string | undefined;
}

//-----------------------------------------------------------------------------------------------
// Context; for model content and data
export interface ECGDataModel {
  heartRateParams: ECGParamModel;
  SPO2Params: ECGParamModel;
  NIBPParams: ECGParamModel;
  temperatureParams: ECGParamModel;
  RRParams: ECGParamModel;
  ETCO2Params: ECGParamModel;
}

//-----------------------------------------------------------------------------------------------
// Context; for model content and data
export interface ECGParamModel {
  low: number;
  high: number;
  flux: number;
  interval: number;
}
