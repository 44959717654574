import { Grid } from "@mui/material";
import { EventsCalanderItem } from "./EventsCalanderItem";

export interface AgricultureEvent{
    startDate: Date; // e.g new Date("1995-12-17T03:24:00")
    endDate: Date;
    eventTitle: string;
    eventLocation: string;
    eventPlace: string;
    eventImageUrl: string;
    eventLinkUrl: string;
}

export interface EventsCalanderProps{
    events: AgricultureEvent[];
}

export function EventsCalander({events}: EventsCalanderProps){
    return (
        <Grid item xs={8} md={7}>
            {events
                // @ts-ignore
                .sort((a: AgricultureEvent, b: AgricultureEvent) => a.startDate - b.startDate)
                .map((event: AgricultureEvent, i: number) => 
                    {
                        let itemStylesObj = {};
                        if(events.length == 1){
                            itemStylesObj["borderRadius"] = "15px 15px 15px 15px";
                        }else if(i == 0){
                            itemStylesObj["borderRadius"] = "15px 15px 0px 0px";
                        }else if(i + 1 == events.length){
                            itemStylesObj["borderRadius"] = "0px 0px 15px 15px";
                            itemStylesObj["borderTop"] = "2px solid #ccc";
                        }else{
                            itemStylesObj["borderRadius"] = "0px 0px 0px 0px";
                            itemStylesObj["borderTop"] = "2px solid #ccc";
                        }

                        return <EventsCalanderItem agricultureEvent={event} itemContainerStylesObj={itemStylesObj} />
                    })}
        </Grid>
    );
}