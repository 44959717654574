import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store';

export enum Lang{
    EN,
    WE
}

// Define a type for the slice state
interface LanguageState {
  currentLanguage: Lang
}

// Define the initial state using that type
const initialState: LanguageState = {
  currentLanguage: Lang.EN,
}

export const languageSlice = createSlice({
  name: 'language',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLanguage: (state: LanguageState, action: PayloadAction<Lang>) => {
      state.currentLanguage = action.payload;
    },
  }
});

export const { setLanguage } = languageSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectLanguage = (state: RootState) => state.languages.currentLanguage;

export default languageSlice.reducer;