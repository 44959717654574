//-----------------------------------------------------------------------------------------------
// Imports
import * as THREE from "three";
import { ScenarioBoxProps } from "../../models/App.model";

//-----------------------------------------------------------------------------------------------
// Function to map the images to a 360 cube
export default function Cube({ image, sideLength }: ScenarioBoxProps) {
  // Sets default side geom from input argument
  const geom = new THREE.PlaneBufferGeometry(sideLength, sideLength, 1, 1);

  return (
    <group>
      {/* Back */}
      <mesh geometry={geom} position={[0, 0, -(sideLength / 2)]}>
        <meshBasicMaterial map={image.back} />
      </mesh>

      {/* Front */}
      <mesh
        geometry={geom}
        position={[0, 0, sideLength / 2]}
        rotation={[0, Math.PI * 1, 0]}
      >
        <meshBasicMaterial map={image.front} />
      </mesh>

      {/* Right */}
      <mesh
        geometry={geom}
        position={[sideLength / 2, 0, 0]}
        rotation={[0, Math.PI * -0.5, 0]}
      >
        <meshBasicMaterial map={image.right} />
      </mesh>

      {/* Left */}
      <mesh
        geometry={geom}
        position={[-(sideLength / 2), 0, 0]}
        rotation={[0, Math.PI * 0.5, 0]}
      >
        <meshBasicMaterial map={image.left} />
      </mesh>

      {/* Up */}
      <mesh
        geometry={geom}
        position={[0, sideLength / 2, 0]}
        rotation={[Math.PI * 0.5, 0, Math.PI * 1]}
      >
        <meshBasicMaterial map={image.up} />
      </mesh>

      {/* Down */}
      <mesh
        geometry={geom}
        position={[0, -(sideLength / 2), 0]}
        rotation={[Math.PI * -0.5, 0, Math.PI * 1]}
      >
        <meshBasicMaterial map={image.down} />
      </mesh>
    </group>
  );
}
