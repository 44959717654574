import { SceneModel, SceneOrderObject } from "../models/scenario.model";
import { SceneLinkedModel } from "../models/ScenarioLinked.model";

// return new copy of an ordered scenes array given a scenesOrder array - 
// which specifies order of scenes to be in
export default function(scenesArr: SceneLinkedModel[], scenesOrderArr: SceneOrderObject[]): SceneLinkedModel[]{
    let scenesByOrderArr: SceneLinkedModel[] = [];
    scenesOrderArr.forEach((sceneOrderObject: SceneOrderObject) => {
        let foundScene: SceneLinkedModel | undefined = 
            scenesArr.find((scene: SceneLinkedModel) => scene.id == sceneOrderObject.sceneId);
        if(foundScene){
            scenesByOrderArr.push(foundScene);
        }else{
            throw new Error("issue with finding a scene object that matches order object id");
        }
    });
    return scenesByOrderArr;
}