//-----------------------------------------------------------------------------------------------
// Imports; React
import { useEffect, useRef } from "react";

//-------------------------------------------------------------------------------------------------------------------------------------------------------
// Interface for the scenario app bar, Scenes to map, method to change scene
export interface ECGLineProps {
  beatInterval: number;
  beatScale: number;
}

//-----------------------------------------------------------------------------------------------
// Function to display the ecg line
export function ECGLine({ beatInterval, beatScale }: ECGLineProps) {
  // Ref to map line to the active canvas
  const canvasRef: any = useRef();

  // Container variables, with the line speed (best performance at < 30)
  const CURVE_COLOR = "#22ff22";
  const HEIGHT = 125;
  const WIDTH = 750;
  const INTERVAL = 30;

  // variables managing the line information
  let heartData = [0, 0, 0, 0, 0];
  let heartDataIndex = 0;
  let beatDataIndex = -2;
  let BANG = false;

  //---------------------------------------------------------------------------------------------
  // Function to fill the heart data array with empty values
  function fillHeartData(length) {
    if (length !== heartData.length) {
      heartData = new Array(length);
      for (let i = 0; i < length; i++) {
        heartData[i] = 0;
      }
    }
  }

  //---------------------------------------------------------------------------------------------
  // Function to add in randomized heart beats
  function fillBeatData() {
    const getValue = (idx) =>
      idx === 0
        ? Math.random() * 0.1 + 0.1
        : idx === 1
        ? Math.random() * 0.1 + 0.0
        : idx === 2
        ? Math.random() * 0.3 + 0.7
        : idx === 3
        ? Math.random() * 0.1 - 0.05
        : idx === 4
        ? Math.random() * 0.3 - 0.8
        : idx === 5
        ? Math.random() * 0.1 - 0.05
        : idx === 6
        ? Math.random() * 0.1 - 0.05
        : idx === 7
        ? Math.random() * 0.1 + 0.15
        : 0;

    // cycles throught the idx entry to make realistic looking heartbeats
    heartData[heartDataIndex] = beatScale * getValue(beatDataIndex);
    beatDataIndex++;
    if (beatDataIndex > 7) {
      beatDataIndex = -1;
    }
  }

  //---------------------------------------------------------------------------------------------
  // Function to add in randomized heart beats
  function fillRandomData() {
    heartData[heartDataIndex] = Math.random() * 0.05 - 0.025;
  }

  //---------------------------------------------------------------------------------------------
  // Updates, the heart data with idle and heart beats
  function updateData() {
    heartDataIndex++;
    if (heartDataIndex >= heartData.length) {
      heartDataIndex = 0;
    } else {
      heartDataIndex++;
    }
    if (beatDataIndex >= 0 || BANG) {
      fillBeatData();
      BANG = false;
    } else {
      fillRandomData();
    }
  }

  //---------------------------------------------------------------------------------------------
  // Maps the information to the canvas
  function ellipse(ctx, x, y, a, b) {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.scale(a / b, 1);
    ctx.arc(0, 0, b, 0, Math.PI * 2, true);
    ctx.restore();
    ctx.closePath();
  }

  //---------------------------------------------------------------------------------------------
  // Maps the information to the canvas
  const onPaint = () => {
    const canvas: any = canvasRef.current;
    [canvas.height, canvas.width] = [HEIGHT, WIDTH];
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, WIDTH, HEIGHT);

    const baseY = HEIGHT / 2;
    const length = heartData.length;
    const step = (WIDTH - 5) / length;
    const yFactor = HEIGHT * 0.35;
    let heartIndex = (heartDataIndex + 1) % length;
    context.strokeStyle = CURVE_COLOR;

    context.beginPath();
    context.moveTo(0, baseY);
    let i = 0,
      x = 0,
      y = 0;
    for (i = 0; i < length; i++) {
      x = i * step;
      y = baseY - heartData[heartIndex] * yFactor;
      context.lineTo(x, y);
      heartIndex = (heartIndex + 1) % length;
    }
    context.stroke();
    context.closePath();

    context.beginPath();
    context.fillStyle = CURVE_COLOR;
    ellipse(context, x - 1, y - 1, 2, 2);
    context.fill();
    context.closePath();
  };

  //---------------------------------------------------------------------------------------------
  // sets the canvas and heart information
  useEffect(() => {
    fillHeartData(Math.max(100, Math.floor(WIDTH * 0.5)));

    const canvasInterval = setInterval(() => {
      const canvas: any = canvasRef.current;

      if (document.contains(canvas)) {
        updateData();
        onPaint();
      } else {
        clearInterval(canvasInterval);
      }
    }, INTERVAL);

    const ecgLineInterval = setInterval(() => {
      BANG = true;
    }, beatInterval);

    return function cleanup() {
      clearInterval(canvasInterval);
      clearInterval(ecgLineInterval);
    };
  }, []);

  return <canvas ref={canvasRef} />;
} // namespace ecg
