// Import; Three
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Html } from "@react-three/drei";
import { VideoHotspotProps } from "../../../models/Hotspot.model";
import { stylesBase } from "../../../styles/styles-base";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Video from "../icons/Video";
import { ContentModel } from "../../../models/scenario.model";
import { CloseRounded } from "@mui/icons-material";
import ReactPlayer from "react-player";

// Style the hotspot.
const useStyles = makeStyles(() => ({
  hotSpot: {
    borderStyle: "solid",
    borderWidth: 7,
    borderColor: "#fcb111",
    height: 75,
    width: 75,
    borderRadius: "50%",
    backgroundColor: "rgba(252, 177, 17, 0.8)",
    transition: "width 0.5s, border-radius 0.3s",
    textAlign: "center",
    "&:hover": {
      // paddingBottom: 30,
      display: "flex",
      alignItems: "center",
      minWidth: 130,
      borderRadius: 75,
      cursor: "pointer"
    },
    "&:hover $imageWrapper": {
      display: "none",
    },
    "&:hover $content": {
      display: "block",
      // verticalyAlign: "middle",
      opacity: 1,
      fontSize: 20,
      // padding: 5,
      marginTop: 1,
      // background: "green",
      padding: "0px 15px",
      width: "100%",
      textAlign: "center",
    },
  },
  mobileHotSpot: {
    borderStyle: "solid",
    borderWidth: 7,
    borderColor: "#fcb111",
    height: 75,
    width: 75,
    borderRadius: "20%",
    backgroundColor: "rgba(252, 177, 17, 0.45)",
    transition: "width 1s, border-radius 1s",
    textAlign: "center",
  },
  noSelect: {
    webkitTouchCallout: "none",
    webkitUserSelect: "none",
    khtmlUserSelect: "none",
    mozuserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  },
  content: {
    color: "#fff",
    marginTop: -12,
    fontSize: 0,
    display: "none",
    fontWeight: "600",
    opacity: 0,
    transition: "visibility 2s, opacity 2s, fontSize 2s",
  },
  mobileContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-4px",
    height: "35px",
    // background: "green",
    color: "white",
    opacity: 1,
    fontSize: 13,
    "$p": {
      margin: "0px"
    }
    // textShadow: "-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black",
    // textAlign: "center"
  },
  imageWrapper: {},
  arrow: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

//-----------------------------------------------------------------------------------------------
// Hotspot component; render the movment, content and board hotspots
export default function VideoHotspot({
  hotspot,
  theme,
  hotspotOpen,
  setHotspotOpen,
}: VideoHotspotProps) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");

  const styleDesk = {
    width: 40,
    height: 45,
    marginTop: 15,
    paddingTop: 0,
  };

  const styleMob = {
    width: 20,
    height: 30,
    marginTop: 5,
    paddingTop: 2
  };

  return (
    <mesh
      position={[hotspot.position.x, hotspot.position.y, hotspot.position.z]}
    >
      <Html scale={10} zIndexRange={[100, 0]} center={true}>
        {!hotspotOpen ? (
          <>
            {matches ? (
              <>
                <div
                  className={clsx(classes.hotSpot, classes.noSelect)}
                  onClick={() => {
                    setHotspotOpen(true);
                  }}
                >
                  <div className={classes.imageWrapper}>
                    <Video
                      width={styleDesk.width}
                      height={styleDesk.height}
                      marginTop={styleDesk.marginTop}
                      paddingTop={styleDesk.paddingTop}
                    />
                  </div>
                  <div className={clsx(classes.content, classes.noSelect)}>
                    <p>{hotspot.name}</p>
                  </div>
                </div>
              </>
            ) : (
              <div
                className={clsx(classes.mobileHotSpot, classes.noSelect)}
                onClick={() => {
                  setHotspotOpen(true);
                }}
              >
                <Video
                  width={styleMob.width}
                  height={styleMob.height}
                  marginTop={styleMob.marginTop}
                  paddingTop={styleMob.paddingTop}
                />

                <div className={clsx(classes.mobileContent, classes.noSelect)}>
                  <p>{hotspot.name}</p>
                </div>
              </div>
            )}
          </>
        ) : (
          <Box component="div">
            {/* // When the hotspot modal is open */}
            <Box component="div" style={stylesBase.desktopHotspotVideoTitle}>
              <Grid container flexDirection={"row"}>
                <Grid item xs={11}>
                  <Typography
                    variant={"h4"}
                    textAlign="center"
                    fontWeight={"700"}
                    color={"#fff"}
                  >
                    {hotspot.title}
                  </Typography>
                </Grid>
                <Grid item xs={0.5}>
                  <IconButton
                    onClick={() => {
                      setHotspotOpen(false);
                    }}
                  >
                    <CloseRounded />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box component="div" style={stylesBase.desktopHotspotVideoContent}>
              {/* If content is defined - Map through each 'row' of content. */}
              {hotspot.content &&
                hotspot.content.map((row: ContentModel, index: number) => {
                  return (
                    <Grid
                      item
                      key={index}
                      container
                      xs={12}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {/* If items exist then map them */}
                      {row.items &&
                        row.items.map((item: any, index: number) => {
                          return (
                            // Each item is equal width using 'xs={12/length} to equally split
                            // each mapped item.
                            <Grid
                              item
                              key={index}
                              container
                              xs={12 / row.items.length}
                              justifyContent={"center"}
                              alignItems={"center"}
                              sx={{position: "relative"}}
                              onClick={() => {
                                setHotspotOpen(false);
                              }}
                            >
                              <ReactPlayer
                                url={item.video}
                                controls={true}
                                crossOrigin={"anonymous"}
                                style={{marginBottom: 5}}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
                })}
            </Box>
          </Box>
        )}
      </Html>
    </mesh>
  );
}
