import {
  Button,
  CircularProgress,
  Grid,
  styled,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Canvas } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../components/navbar/NavBar";
import {
  changeScene,
  getFallBackImages,
  goToErrorPage,
  renderHotspots,
} from "../helpers/scenario.helpers";
import { SceneModel } from "../models/scenario.model";
import { SceneLinkedModel } from "../models/ScenarioLinked.model";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { ScenarioState } from "../store/slices/scenario.slice";
import { RootState } from "../store/store";
import { getScenario } from "../store/thunks/scenario.thunks";
import { PerspectiveCamera } from "@react-three/drei";
import theme from "../styles/theme";
import Cube from "../components/views/Cube";
import Controls from "../components/views/Controls";
import ScenarioSceneStepper from "../components/steppers/ScenarioSceneStepper";
import { HintsBox } from "../components/hints/HintsBox";
import hintsCharacterImg from "../assets/images/character1.png";
import { Hints } from "../models/hints.model";
import { ActorHints } from "../components/hints/ActorHints";

function Scenario() {
  // const navigate = useNavigate();

  // Set attribute and gets the scenario Id and potential scene id form the url
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { scenarioId, sceneId }: any = useParams();

  // Dialog state for hotSpot clicks
  const defaultInfoConfig = {
    id: "1234",
    name: "",
    welsh: "",
    x: 2.1702631077735672,
    y: -46.29785480501342,
    z: -88.61037523053909,
    type: "info",
    moveTo: "",
    modalTitle: "",
    modalContent: "",
    modalUrl: "",
    modalUrlText: "",
  };

  const [open, setOpen] = useState<Boolean>(false);
  const [hotSpotConfig, setHotSpotConfig] = useState<any>(defaultInfoConfig);

  const [showHints, setShowHints] = useState<boolean>(false);
  useEffect(() => {
    const timerId = window.setTimeout(() => {
      setShowHints(true);
    }, 1500);
    return () => window.clearTimeout(timerId);
  }, []);

  // Modals handlers; audio to be open initially on a brief page, content top handle the user answers
  const [openAudioModal, setOpenAudioModal] = useState(true);
  const [openContentModal, setOpenContentModal] = useState(false);

  // Sets when the first set of images is loaded,
  // to display the cube and load the rest of the scenarios images
  const [firstImageLoaded, setFirstImageLoaded] = useState<boolean>(false);
  const [sceneImages, setSceneImages] = useState<any[]>([]);

  // Scenario data; retrieved from api using the redux store,
  // with the current scene information stored as variables

  // const { scenarioError, scenarioData, scenarioLoading } = useSelector(
  //     (state: any) => state.ScenarioSlice
  // );

  const { scenarioError, scenarioData, scenarioLoading } =
    useAppSelector<ScenarioState>((state: RootState) => state.scenario);

  console.log("scenario data is:");
  console.log(scenarioData);

  const [currentScene, setCurrentScene] = useState<
    SceneLinkedModel | undefined
  >(undefined);
  const [currentImage, setCurrentImage] = useState<any | undefined>(undefined);

  //---------------------------------------------------------------------------------------------
  // Retrieves the Scenario data from the redux store
  useEffect(() => {
    dispatch(getScenario({ scenarioId, navigate }));
  }, [dispatch]);

  // Set ths current scene from scenario data based on URL
  useEffect(() => {
    try {
      // Checks data is loaded
      if (scenarioData && scenarioData.scenes) {
        // If url specifies a scene, set as first scene
        if (sceneId !== undefined) {
          changeScene(sceneId, scenarioData, setCurrentScene);
        }

        // If URL no specified try to find a scene of type brief
        else {
          const startScene = scenarioData.scenes.find(
            (val: SceneModel) => val.type === "brief"
          );

          // If scene of type brief exist set that as first scene
          if (startScene) {
            setCurrentScene(startScene);
          }

          // If no scenes of type brief exist set first scene
          else {
            setCurrentScene(scenarioData.scenes[0]);
          }
        }
      }
    } catch (e) {
      // On error navigate to error page
      goToErrorPage(navigate, "can't find scene");
    }
  }, [scenarioData]);

  // loads the current scene when set, buffers all scene images on first load
  useEffect(() => {
    if (currentScene) {
      // If no images have yet been loaded and there is data
      if (scenarioData && scenarioData.scenes && !firstImageLoaded) {
        // Get the current scene images using a helper
        let tempCurrentScene = getFallBackImages(currentScene.image);
        let tempSceneImages = [tempCurrentScene];

        // set the initial image set and display
        setCurrentImage(tempCurrentScene);
        setFirstImageLoaded(true);

        // Loads the rest of the fallback images, ignoring already loaded or repeated images
        scenarioData.scenes.forEach((element) => {
          if (!tempSceneImages.find((i) => i.directory === element.image)) {
            tempSceneImages.push(getFallBackImages(element.image));
          }
        });

        // Set the buffered image array
        setSceneImages(tempSceneImages);
      }

      // Changes the current scene, if not the initial scene, using buffered image
      if (scenarioData && scenarioData.scenes && firstImageLoaded) {
        setCurrentImage(
          sceneImages.find((i) => i.directory === currentScene.image)
        );
      }
    }
  }, [currentScene]);

  const hints: Hints = {
        "EN":  [
            "welcome to a scenario!",
            "drag the screen to navigate a scene",
            "use the stepper below to track your progress or skip scenes"
        ],
        "WE": [
            "croeso i senario!",
            "llusgwch y sgrin i lywio golygfa",
            "defnyddiwch y stepiwr isod i olrhain eich cynnydd neu hepgor golygfeydd"
        ]
  }

  return (
    <Grid container item sx={{ background: "#eee" }}>
      {showHints && <ActorHints 
                        actorName="John, Welsh Wool"
                        hints={hints} 
                        actorImageUrl={hintsCharacterImg} 
                        onAllHintsRead={() => setShowHints(false)} 
                        onExitClicked={() => setShowHints(false)}  
                        />}
      <NavBar />
      {/* While the scenario data or the first image is loading, show loading spinner */}
      {scenarioLoading || !firstImageLoaded ? (
        <Grid
          justifyContent={"center"}
          alignContent={"center"}
          container
          style={{ height: "calc(100vh - 70px)" }}
        >
          <CircularProgress size={150} />
        </Grid>
      ) : (
        <>
          <Grid container sx={{ height: "calc(100vh - 70px)", "&:hover": {cursor: "grab"}, "&:active": {cursor: "grabbing"} }}>
            {scenarioData && currentScene && (
              <ScenarioSceneStepper
                currentScenario={scenarioData}
                currentScene={currentScene}
                onSelectedScene={(scene: SceneLinkedModel) => {
                  setCurrentScene(scene);
                }}
              />
            )}

            {/*---------------------------------------------------------------------------------*/}
            {/* If no issues show the canvas with scene image box */}
            <Canvas style={{ height: "100%" }}>
              {/* User interaction functionality */}
              <PerspectiveCamera makeDefault={true} position={[0, 0, -0.1]} />
              <Controls />
              <ambientLight />

              {/* Map current scene images to box */}
              <Cube image={currentImage} sideLength={500} />

              {/* Render hotspots if the current scene exists */}
              {currentScene &&
                scenarioData &&
                renderHotspots(
                  currentScene,
                  scenarioData,
                  setCurrentScene,
                  navigate,
                  theme
                )}
            </Canvas>
          </Grid>
        </>
      )}

      {/* <VirtusFooter />  */}
    </Grid>
  );
}

export { Scenario };
