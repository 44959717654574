//-----------------------------------------------------------------------------------------------
// Imports; react
import { Theme } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CaseInfoModel } from "../models/Case.model";
import { PatientModel } from "../models/patient.model";
import * as THREE from "three";

// Import ; models, components
import {
  HotspotModel,
  HotspotType,
  ScenarioModel,
  SceneModel,
} from "../models/scenario.model";
import {
  ScenarioLinkedModel,
  SceneLinkedModel,
} from "../models/ScenarioLinked.model";
import Hotspot from "../components/hotspots/Hotspot";

//-----------------------------------------------------------------------------------------------
// Changes the current scene by id
export function changeScene(
  id: number,
  scenarioData: ScenarioLinkedModel,
  setCurrentScene: Function
) {
  if (scenarioData) {
    setCurrentScene(
      scenarioData.scenes.find(
        (val: SceneLinkedModel) => val.id === Number.parseInt(id.toString())
      )
    );
  }
}

//-----------------------------------------------------------------------------------------------
// Navigates tot the error page
export function goToErrorPage(
  navigate: NavigateFunction,
  errorMessage: string
) {
  navigate(`/error/${errorMessage}`);
}

//-----------------------------------------------------------------------------------------------
// Method to render all hotspots within a given scene
// currentScene: SceneModel = current scene the user is in to get hotspots for
// scenarioData: ScenarioModel = all data for the scenario the user is in
// setCurrentScene: Function = set current scene function
export function renderHotspots(
  currentScene: SceneModel,
  scenarioData: ScenarioModel,
  setCurrentScene: Function,
  navigate: NavigateFunction,
  theme: Theme
) {
  if (currentScene.hotspots) {
    //Map through each hotspot
    return currentScene.hotspots.map((hotspot: HotspotModel, i: number) => {
      return (
        // Custom hotspot object
        <Hotspot
          key={`${hotspot.id}-${i}`}
          hotspot={hotspot}
          handleClick={() => {
            if (hotspot.type === HotspotType.MOVEMENT) {
              if (hotspot.action !== undefined) {
                //Go to scene
                changeScene(hotspot.action, scenarioData, setCurrentScene);
              } else {
                goToErrorPage(navigate, "issue changing scene");
              }
            }
          }}
          scenarioData={scenarioData}
          setCurrentScene={setCurrentScene}
          theme={theme}
        />
      );
    });
  }
}

//---------------------------------------------------------------------------------------------
// Gets the images for the fallback images
export function getFallBackImages(imageDirectory) {
  const loader = new THREE.TextureLoader();
  const backTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/b.jpg`
  );
  const frontTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/f.jpg`
  );
  const leftTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/l.jpg`
  );
  const rightTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/r.jpg`
  );
  const upTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/u.jpg`
  );
  const downTexture = loader.load(
    `${process.env.PUBLIC_URL}/static/data/scenarios/images/${imageDirectory}/d.jpg`
  );

  let tempImage = {
    directory: imageDirectory,
    left: leftTexture,
    right: rightTexture,
    up: upTexture,
    down: downTexture,
    front: frontTexture,
    back: backTexture,
  };

  return tempImage;
}
