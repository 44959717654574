import { Button, Grid, styled, Typography } from "@mui/material";
import PaperStyledCustomization from "../paper/PaperCustom";
import theme from "../../styles/theme";
import tempScenarioImage from "../../assets/images/HomeBlogImage.png";
import communityImage from "../../assets/images/comm_sheep_img.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Lang, selectLanguage, setLanguage } from "../../store/slices/language.slice";
import getTranslation from "../../assets/localisation/getTranslation";

interface CommBoxProps {
  children?: React.ReactNode;
  onClick?: any;
  props?: any;
}

const CommBoxButton = styled(Button)<CommBoxProps>(({ onClick, children }) => ({
  background: "#fff",
  color: "#000",
  width: "150px",
  fontWeight: "bold",
  borderRadius: "15px",
  "&:hover": {
    background: "#555",
    color: "#fff",
  },
}));

export default function CommunityBox() {
  const navigate = useNavigate();
  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

  return (
    <Grid container flexDirection={"column"}>
      {/* Paper to create buffer around the image */}
      <PaperStyledCustomization
        sx={{
          height: 50,
          backgroundColor: "#eee",
          borderRadius: 0,
          boxShadow: "none",
        }}
      />
      <Grid
        container
        justifyContent={"center"}
        alignItems="center"
        sx={{
          marginTop: "30px",
          backgroundColor: theme.palette.primary.main,
          borderTop: "1px solid #555",
          borderBottom: "1px solid #555",
        }}
      >
        <Grid
          item
          xs={7}
          lg={4}
          xl={3}
          sx={{
            marginBottom: {
              xs: "-70px",
              sm: "-70px",
              md: "-50px",
              lg: "-100px",
            },

            marginTop: {xs: "-60px", sm: "-40px", md: "-60px", lg: "-95px", xl: "-95px" },
          }}
        >
          <img
            style={{
              position: "relative",
              // marginTop: "-10px",
              width: "100%",
              // maxWidth: "800px",
              display: "inline",
              borderRadius: "50%",
            }}
            src={communityImage}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid
          item
          container
          xs={10}
          lg={6}
          direction={"row"}
          justifyContent={"left"}
          alignItems={"center"}
        >
          <Grid item xs={12} lg={12}>
            <Typography
              variant="h3"
              color="#555"
              sx={{
                fontWeight: 700,
                marginTop: { md: 8, xs: 10 },
                textAlign: { lg: "left", xs: "center" },
              }}
            >
              {getTranslation("commTitle", currentSelectedLanguage)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: { lg: "left", xs: "center" } }}
              variant="body1"
              color="text.secondary"
              marginTop={3}
            >
              {getTranslation("commText", currentSelectedLanguage)}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={4}
            sx={{
              justifyContent: { lg: "left", xs: "center" },
              marginBottom: "50px",
              marginTop: "5px",
              paddingTop: "20px",
            }}
          >
            <CommBoxButton
              onClick={() => {
                navigate("/community");
              }}
            >
              {getTranslation("communityLearnMoreButtonText", currentSelectedLanguage)}
            </CommBoxButton>
          </Grid>
        </Grid>
      </Grid>
      <PaperStyledCustomization
        sx={{ height: { xs: 0, md: 50 }, boxShadow: "none" }}
      />
    </Grid>
  );
}
