import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import getTranslation from "../../assets/localisation/getTranslation";
import { useAppSelector } from "../../store/hooks";
import { Lang, selectLanguage } from "../../store/slices/language.slice";
import Slider from "react-slick";
import Timeline from "./Timeline";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#eee" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#eee" }}
      onClick={onClick}
    />
  );
}

export default function TwitterFeedBox() {
  const currentSelectedLanguage: Lang = useAppSelector<Lang>(selectLanguage);

  const [feeds, setFeeds] = useState([
    { name: "MenterMon" },
    { name: "BritishWoolFarm" },
    { name: "BritishWool" },
    { name: "Campaignforwool" },
    { name: "natsheep" },
  ]);

  const matchesSmall = useMediaQuery("(min-width:1000px)");
  const matchesMedium = useMediaQuery("(min-width:1920px)");

  const slideCount = useState();

  return (
    <Grid container justifyContent={"space-evenly"}>
      <Grid xs={12} sx={{ width: "90%", margin: 20, marginTop: 10 }}>
        <Typography
          variant="h3"
          align="center"
          color="#555"
          sx={{ fontWeight: 700, marginBottom: 5 }}
        >
          {getTranslation("communityPageTwitterTitle", currentSelectedLanguage)}
        </Typography>
        <Slider
          slidesToShow={matchesMedium ? 5 : matchesSmall ? 3 : 1}
          {...{
            dots: true,
            infinite: true,
            speed: 1200,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            autoplay: true,
            autoplaySpeed: 3500,
          }}
          slidesPerRow={1}
        >
          {feeds.map(({ name, slidesToShow }: any) => (
            <>
              {matchesMedium || matchesSmall ? (
                <>
                  <Grid item xs={11} spacing={1} alignItems="center">
                    <Timeline screenName={name} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} alignItems="center">
                    <Timeline screenName={name} />
                  </Grid>
                </>
              )}
            </>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
}
