import { createAsyncThunk } from "@reduxjs/toolkit";
import { AnyAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import axios, { AxiosResponse } from "axios";
import { goToErrorPage } from "../../helpers/scenario.helpers";
import { ScenarioModel, SceneModel } from "../../models/scenario.model";
import { CaseLinkedModel, ScenarioLinkedModel, SceneLinkedModel } from "../../models/ScenarioLinked.model";
import getSortedScenesArrByOrder from "../../utils/getSortedScenesArrByOrder";

// process.env.REACT_APP_IS_DEV === 'true';

// Gets the scenario config data
export const getScenarioConfig = createAsyncThunk(
    "GetScenarioConfigData",
    async (): Promise<any[]> => {

      const endpoint: string = process.env.REACT_APP_IS_DEV === 'true'
      ? `/static/data/scenarios_config.json` // gets from local public folder
      : `${process.env.REACT_APP_ENDPOINT}/shift_scenario/scenarios_config_data.json`; // change this to welsh wool api when ready

      // console.log("endpoint");
      // console.log(endpoint);

      const response: AxiosResponse<any[]> = await axios.get(
        endpoint
      );
      return response.data;
    }
  );

// Gets the scenario data by id, and data is marshaled into an object of type: ScenarioLinkedModel
export const getScenario = createAsyncThunk(
  "GetScenarioData",
  async (props: any): Promise<ScenarioLinkedModel | undefined> => {

    const endpoint: string = process.env.REACT_APP_IS_DEV === 'true'
      ? `/static/data/scenarios/${props.scenarioId}.json` // gets from local public folder
      : `${process.env.REACT_APP_ENDPOINT}/shift_scenario/scenarios/${props.scenarioId}.json`; // change this to welsh wool api when ready

    // console.log("ENDPOINT IS");
    // console.log(endpoint);

    try{
      const response: AxiosResponse<ScenarioLinkedModel> = await axios.get(endpoint);
      
      // return response.data;


      // PASTED FROM HERE
      if(response.data){
        let scenarioData: ScenarioModel = response.data;
       
        let newLinkedScenario: ScenarioLinkedModel = {
          id: scenarioData.id,
          scenes: [],
          viewMode: scenarioData.viewMode
        };
  
        for(let i=0;i<scenarioData.scenes.length; i++){
          //Get case for scene if needed
          //Get patient for case too.
          let tempScene: SceneModel = scenarioData.scenes[i];
    
          // Create new object using linked version of models
          let newLinkedScene: SceneLinkedModel = {
            id: tempScene.id,
            name: tempScene.name,
            type: tempScene.type,
            image: tempScene.image,
            preview: tempScene.preview,
            scenes: tempScene.scenes,
            textures: tempScene.textures,
            audio: tempScene.audio,
            hotspots: tempScene.hotspots
          }
    
          // If there is a case attached, get this information
          // if(tempScene.caseId !== undefined){
          //   //Find the case & add to object!
          //   let caseResponse = await axios.get(
          //     `${process.env.REACT_APP_ENDPOINT}/shift_scenario/cases/${tempScene.caseId}.json`
          //   );
    
          //   // If the case exists
          //   if(caseResponse.data){
          //     let newLinkedCase: CaseLinkedModel = {
          //       id: caseResponse.data.id,
          //       name: caseResponse.data.name,
          //       sceneId: caseResponse.data.sceneId,
          //       patient: caseResponse.data.patient,
          //       declaredReason: caseResponse.data.declaredReason,
          //       generalDescription: caseResponse.data.generalDescription,
          //       sections: caseResponse.data.sections,
          //       boardItems: caseResponse.data.boardItems,
          //     };
  
              
          //     let patientResponse = await axios.get(
          //     `${process.env.REACT_APP_ENDPOINT}/shift_scenario/patients/${caseResponse.data.patientId}.json`
          //     );
  
          //     // If the patient exists
          //     if(patientResponse.data){
          //       newLinkedCase.patient = patientResponse.data;
  
          //       // Update the scene to be holding the case and patient
          //       newLinkedScene.case = newLinkedCase;
          //     } else{
          //       // Go to error page if the patient doesn't exist - TEMPORARY - Not very helpful if one case is missing
          //       goToErrorPage(props.navigation, "patient doesn't exist");
          //     }
          //   } else{
          //     // Go to error page if the case doesn't exist - TEMPORARY - Not very helpful if one case is missing
          //     goToErrorPage(props.navigation, "case doesn't exist");
          //   }
          // }
          newLinkedScenario.scenes[i] = newLinkedScene;
        }
        
        // sort sceneario scenes by order data if scenesOrder array data exists on scenarioData object from server
        if(scenarioData.scenesOrder){
          try{
            // override .scenes prop array with newly sorted/in order scenes array
            newLinkedScenario.scenes = getSortedScenesArrByOrder(newLinkedScenario.scenes, scenarioData.scenesOrder);
          }catch(e: any){
            // if error with any scenes matching order object id's from server - 
            // log specific error message to console for to make debugging easier and render error page
            // if(err instanceof Error){
            //   console.log(err.message);
            // }
            goToErrorPage(props.navigate, e.toString());
          }
        }
  
        return newLinkedScenario; 
      }

    }catch(e: any){
      console.log("request response issue");
      goToErrorPage(props.navigate, e.toString());
    }
});